import {useState} from 'react';
import ContentLoader from 'react-content-loader';

interface PlaceholderImageProps {
  className: string;
  uri: string | undefined;
  alt: string;
  style: React.CSSProperties;
  onClick?: () => void;
}

export default function PlaceholderImage(props : PlaceholderImageProps) {
  const [isImageLoaded, setImageLoaded] = useState("");

  return (<div className={props.className}>
            <div style={isImageLoaded === props.uri && isImageLoaded !== "" ? {display: "none"} : {display: "block"}}>
              <ContentLoader className={props.className} style={props.style}>
                <rect x="0" y="0" rx="0" ry="10" width="100%" height="100%" />
              </ContentLoader>
            </div>

            <div style={isImageLoaded === props.uri && isImageLoaded !== "" ? {display: "block"} : {display: "none"}}>
              <img className={props.className} src={props.uri} style={props.style} alt={props.alt} onLoad={() => {setImageLoaded(props.uri ?? "");}} onClick={props.onClick}/>
            </div>
          </div>);
}