import React from 'react';
import Parallax from 'parallax-js';

import CSS from 'csstype';
import {dispatch} from 'use-bus';

import introLayerOne from '../assets/img/demo/presentation/intro/layer01.png';
import introLayerTwo from '../assets/img/demo/presentation/intro/layer02.png';
import introLayerThree from '../assets/img/demo/presentation/intro/layer03.png';
import introLayerFour from '../assets/img/demo/presentation/intro/layer04.png';
import introLayerFive from '../assets/img/demo/presentation/intro/layer05.png';
import introLayerSix from '../assets/img/demo/presentation/intro/layer06.png';
import introLayerSeven from '../assets/img/demo/presentation/intro/layer07.png';
import introLayerEight from '../assets/img/demo/presentation/intro/layer08.png';
import introLayerNine from '../assets/img/demo/presentation/intro/layer09.png';
import StatsComponent from './StatsComponent';

export class HeroSection extends React.Component {


  componentDidMount() {
    var element = document.querySelectorAll('.parallax');

    for (var i = 0; i < element.length; i++) {
      new Parallax(element[i]);
    }
  }

  displayMintModal() {
    dispatch('@mintModal/display');
  }

  render() {
    const parallaxStyle: CSS.Properties = {
      maxWidth: '920px'
    }
  
    const parallalxLayerTwoStyle: CSS.Properties = {
      zIndex: 2
    }
  
    const parallalxLayerThreeStyle: CSS.Properties = {
      zIndex: 3
    }
  
    const parallalxLayerFourStyle: CSS.Properties = {
      zIndex: 4
    }
  
    return (
        <section className="header d-flex align-items-center position-relative bg-dark bg-size-cover bg-position-center min-vh-100 overflow-hidden pt-6 pb-lg-5">
          <div className="container-fluid pt-4 pb-5 py-lg-5">
            <div className="row align-items-center py-3">
              <div className="col-xl-6 col-lg-5 d-flex justify-content-end">
                <div className="pt-2 mx-auto mb-5 mb-lg-0 ms-lg-0 me-xl-7 text-center text-lg-start">
                  <h1 className="display-4 text-light pb-2">
                    <span className="fw-light">Get a</span> First Derivative
                  </h1>
                  <p className="h4 fw-light text-light opacity-70 line-height-base mb-5">Discover and collect affordable NFT derivatives which track the NFTs you already love.</p>
                  <div className="flex flex-col items-center justify-center mb-4">
                    <button type="button" onClick={this.displayMintModal} className="btn btn-success">Get started</button>
                  </div>                  
                  <hr className="hr-light mt-0 mb-5"/>
                  <StatsComponent />
                </div>              
              </div>
              <div className="col-xl-6 col-lg-7">
                <div className="parallax ms-lg-n5" style={parallaxStyle}>
                    <div className="parallax-layer position-relative" data-depth="0.1"><img src={introLayerOne} alt="Layer"/></div>
                    <div className="parallax-layer" style={parallalxLayerTwoStyle} data-depth="0.3"><img src={introLayerTwo} alt="layer"/></div>
                    <div className="parallax-layer" data-depth="0.2"><img src={introLayerThree} alt="Layer"/></div>
                    <div className="parallax-layer" style={parallalxLayerThreeStyle} data-depth="0.1"><img src={introLayerFour} alt="Layer"/></div>
                    <div className="parallax-layer" data-depth="0.15"><img src={introLayerFive} alt="Layer"/></div>
                    <div className="parallax-layer" style={parallalxLayerFourStyle} data-depth="0.25"><img src={introLayerSix} alt="Layer"/></div>
                    <div className="parallax-layer" data-depth="0.3"><img src={introLayerSeven} alt="Layer"/></div>
                    <div className="parallax-layer" data-depth="0.4"><img src={introLayerEight} alt="Layer"/></div>
                    <div className="parallax-layer" data-depth="0.35"><img src={introLayerNine} alt="Layer"/></div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}