import React, {useState} from 'react';

import { useWeb3React } from "@web3-react/core"
import MintForm from './MintForm';
import { Modal, Button } from 'react-bootstrap';
import ConnectWalletForm from './ConnectWalletForm';
import useBus from 'use-bus';

export default function MintModal() {

  const { active } = useWeb3React()

  const [visible, setVisible] = useState(false);

  const [requestedContractId, setRequestedContractId] = useState<string | undefined>(undefined);
  const [requestedTokenId, setRequestedTokenId] = useState<string | undefined>(undefined);

  useBus('@mintModal/display', (event) => {
    setRequestedContractId(event.payload?.contractId);
    setRequestedTokenId(event.payload?.tokenId);
    setVisible(true);
  }, [visible]);

  const handleDismiss = () => {
    setVisible(false);
  }

  function contentTitle() {
    if (active) return "Create a First Derivative";
    else        return "Connect your wallet";
  }

  function contentBody() {
    if (active) return (<MintForm contractId={requestedContractId} tokenId={requestedTokenId}/>)
    else        return (<ConnectWalletForm />)
  }

  return (<Modal show={visible} onHide={handleDismiss} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{contentTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {contentBody()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDismiss}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>);
}