import { Provider } from '@ethersproject/abstract-provider';
import { BigNumberish } from '@ethersproject/bignumber';
import { ERC165, ERC165__factory, ERC721, ERC721__factory } from '../typechain/';
import { CryptopunksData } from '../typechain/CryptopunksData';
import { CryptopunksData__factory } from '../typechain/factories/CryptopunksData__factory';
import { CRYPTOPUNKS_ADDRESS, CRYPTOPUNKS_DATA_ADDRESS } from './Constants';
import svgToMiniDataURI from 'mini-svg-data-uri';
import { ERC1155 } from '../typechain/ERC1155';
import { ERC1155__factory } from '../typechain/factories/ERC1155__factory';
import { NFTMetadata } from './NFTMetadata';
import { fetchMetadata } from './NetworkConnector';

export class ContractFascade {

  private contractId : string;

  private erc721          : ERC721 | undefined;
  private cryptopunksData : CryptopunksData | undefined;
  private erc1155         : ERC1155 | undefined;

  private constructor(contractId : string) {
    this.contractId = contractId;
  }

  public async name() : Promise<string> {
    if (this.erc721 !== undefined) {
      return await this.erc721.name();
    } else if (this.erc1155 !== undefined) {
      return "ERC1155";
    } else if (this.cryptopunksData !== undefined) {
      return "CryptoPunks";
    }

    throw new Error("Not a supported contract");
  }

  public async tokenMetadata(tokenId : BigNumberish) : Promise<NFTMetadata> {
    if (this.erc721 !== undefined) {
      return await fetchMetadata(this.contractId, tokenId.toString(), "721");
    } else if (this.erc1155 !== undefined) {
      return await fetchMetadata(this.contractId, tokenId.toString(), "1155");
    } else if (this.cryptopunksData !== undefined) {
      console.log("Querying for svg: " + tokenId);
      const punkSvg    = await this.cryptopunksData.punkImageSvg(tokenId);
      const justSvg    = punkSvg.substr("data:image/svg+xml;utf8,".length);
      const encodedSvg = svgToMiniDataURI(justSvg);

      return {"name" : `CryptoPunk #${tokenId}`, "image": encodedSvg, "description" : ""};
    }

    throw new Error("Not a supported contract");
  }

  public static Create = async(contractId : string, library : Provider) => {
    const fascade = new ContractFascade(contractId);

    if (contractId === CRYPTOPUNKS_ADDRESS) {
      fascade.cryptopunksData = CryptopunksData__factory.connect(CRYPTOPUNKS_DATA_ADDRESS, library);
    } else {
      const erc165    : ERC165  = ERC165__factory.connect(contractId, library);
      const isErc721  : boolean = await erc165.supportsInterface("0x80ac58cd");
      const isErc1155 : boolean = await erc165.supportsInterface("0xd9b67a26");

      if (isErc721) {
        fascade.erc721 = ERC721__factory.connect(contractId, library);
      } else if (isErc1155) {
        fascade.erc1155 = ERC1155__factory.connect(contractId, library);
      } else {
        throw new Error("Contract is not derivative capable");
      }
    }

    return fascade;
  }

}