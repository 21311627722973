import React from 'react';

import './App.css';

import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';

import { HeroSection } from './components/HeroSection';
import RecentDerivativesSection from './components/RecentDerivativesSection';
import PopularNFTSection from './components/PopularNFTSection';
import MintModal from './components/MintModal';
import FaqSection from './components/FaqSection';

function getLibrary(provider: ethers.providers.ExternalProvider) {
  return new ethers.providers.Web3Provider(provider);
}

const NetworkWeb3ReactRoot = createWeb3ReactRoot("network");

function App() {

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <NetworkWeb3ReactRoot getLibrary={getLibrary}>
        <main className="page-wrapper">
          <HeroSection/>
          <RecentDerivativesSection/>
          <PopularNFTSection/>
          <FaqSection />
          <MintModal/>
        </main>
        <footer className="footer bg-dark pt-5 pt-md-6 pt-lg-7"></footer>
      </NetworkWeb3ReactRoot>
    </Web3ReactProvider>
  );
}

export default App;
