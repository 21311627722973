import React, {useEffect, useState} from 'react';
import { Network, OpenSeaAPI } from 'opensea-js';
import { POPULAR_COLLECTIONS } from '../util/Constants';
import PlaceholderImage from './PlaceholderImage';

interface ResolvedPopularCollection {
  address: string;
  name: string;
  image: string;
}

interface MintCollectionsRailProps {
  onCollection: (collectionId: string) => void;
}

export default function MintCollectionsRail(props: MintCollectionsRailProps) {

  const [resolvedPopularCollections, setResolvedPopularCollections] = useState<ResolvedPopularCollection[]>([])
  const [isLoaded, setLoaded]                                       = useState(false);

  useEffect(() => {
    async function getPopularCollectionsData() {
      const api                 = new OpenSeaAPI({networkName: Network.Main});
      const resolvedCollections = await Promise.all(POPULAR_COLLECTIONS.map(async (address) => {
        const result = await api.get("/api/v1/asset_contract/" + address);
        return {image: result.collection.featured_image_url ?? result.image_url, name: result.collection.name, address: address};
      }));
   
      setResolvedPopularCollections(resolvedCollections);
      setLoaded(true);
    }

    getPopularCollectionsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(resolvedPopularCollections)]);

  function renderCollections() {
    if (isLoaded) {
      return resolvedPopularCollections.map((collection) => {
        return (<div>
                  <PlaceholderImage className="rounded" uri={collection.image} style={{minHeight: "100px", minWidth: "100px", objectFit: "cover"}} onClick={() => props.onCollection(collection.address)} alt={collection.name} />
                </div>
        );
      });
    } else {
      return (<>
        <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
        <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
        <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
        <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
        <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
        <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
        </>
        );
    }
  }

  return (
      <div className="d-flex flex-row flex-nowrap gap-3 overflow-auto ">
        {renderCollections()}
      </div>);
}