import React, {useEffect, useState} from 'react';
import { Network, OpenSeaAPI } from 'opensea-js';
import { OpenSeaAsset } from 'opensea-js/lib/types';
import PlaceholderImage from './PlaceholderImage';

interface MintTokenRailProps {
  collectionId: string;
  onTokenId: (tokenId: string) => void
}

export default function MintTokensRail(props: MintTokenRailProps) {

  const [resolvedTokens, setResolvedTokens] = useState<OpenSeaAsset[]>([])
  const [isResolved, setResolved]           = useState(false);

  useEffect(() => {
    setResolved(false);
  }, [props.collectionId]);

  useEffect(() => {
    async function getTokens() {
      const api    = new OpenSeaAPI({networkName: Network.Main});
      const assets = await api.getAssets({asset_contract_address: props.collectionId, order_by: "sale_price", order_direction: "desc"});

      setResolvedTokens(assets.assets);
      setResolved(true);
    }

    if (props.collectionId.length > 0) getTokens();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(resolvedTokens), props.collectionId]);

  function renderCollections() {
    if (isResolved) {
      return resolvedTokens.map((token) => {
        return (<div>
                  <PlaceholderImage className="rounded" uri={token.imageUrlThumbnail} onClick={() => props.onTokenId(token.tokenId ?? token.tokenAddress)} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="NFT" />
                </div>
        );
      });
    } else {
      return (<>
              <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
              <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
              <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
              <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
              <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
              <div><PlaceholderImage className="rounded" uri={undefined} style={{minHeight: "100px", minWidth: "100px", maxWidth: "100px", maxHeight: "100px", objectFit: "cover"}} alt="Loading placeholder" /></div>
              </>
              );
    }
  }

  return (
      <div className="d-flex flex-row flex-nowrap gap-3 mt-3 overflow-auto ">
        {renderCollections()}
      </div>);
}