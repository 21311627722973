import { NFTMetadata } from '../util/NFTMetadata';

import { FIRST_DERIVATIVE_ADDRESS } from '../util/Constants';
import { BigNumber } from '@ethersproject/bignumber';

import {dispatch} from 'use-bus';
import { utils as ethersUtils} from 'ethers';
import ethLogo from '../assets/img/eth.svg';
import PlaceholderImage from './PlaceholderImage';
import ContentLoader from 'react-content-loader';

interface NFTCardProps {
  metadata : NFTMetadata | undefined;
  contractId : string;
  tokenId : string;
  derivativeId: BigNumber;
  displayButton: boolean;
  lastSalePrice : string | undefined;
}


export default function NFTCard(props : NFTCardProps) {

  async function handleMint() {
    dispatch({type: '@mintModal/display', payload: {contractId: props.contractId, tokenId: props.tokenId}});
  };

  function actionButton() {
    if (!props.displayButton) return (<></>);

    if (props.derivativeId.isZero()) {
      return (<button type="button" onClick={handleMint} className="btn btn-success">Mint derivative</button>);
    } else {
      return (<a href={"https://rarible.com/token/" + FIRST_DERIVATIVE_ADDRESS + ":" + props.derivativeId + "?tab=details"} className="btn btn-info" role="button" target="_blank" rel="noreferrer">View derivative</a>);
    }
  }

  function truncatedDescription() {
    const description = props.metadata?.description;

    if (description !== undefined && description !== null && description.length > 150) {
      return description.substring(0, 150) + "...";
    }

    return description;
  }

  function saneImageUri() {
    if (props.metadata?.image.startsWith("ipfs://ipfs")) {
      return "https://ipfs.io/ipfs/" + props.metadata?.image.substr("ipfs://ipfs/".length);
    } else if (props.metadata?.image.startsWith("ipfs://")) {
      return "https://ipfs.io/ipfs/" + props.metadata?.image.substr("ipfs://".length);
    }

    return props.metadata?.image;
  }

  function renderLastSalePrice() {
    if (props.lastSalePrice !== undefined) return (<p className="text-muted">Underlying price: <img src={ethLogo} style={{width: "14px", height: "14px"}} alt="Price in eth"/> {ethersUtils.formatEther(props.lastSalePrice).toString()}</p>);
    else                                   return (<></>);
  }

  function renderCardTitle() {
    if (props.metadata) {
      return props.metadata?.name;
    } else {
      return (<ContentLoader viewBox="0 0 400 20">
                <rect x="0" y="0" rx="10" ry="10" width="70%" height="20px" />
              </ContentLoader>);
    }
  }
  
  return (
    <div className="card">
      <PlaceholderImage className="card-img-top" uri={saneImageUri()} alt="NFT" style={{height: "300px", objectFit: "cover"}} />

      <div className="card-body">
        <h5 className="card-title">{renderCardTitle()}</h5>
        {renderLastSalePrice()}
        <p className="card-text">{truncatedDescription()}</p>

        {actionButton()}
      </div>
    </div>
  );

}