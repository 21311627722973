export default function FaqSection() {
  return (<section className="container py-5 py-md-6 py-lg-7">
            <div className="text-center mb-5 pt-3 pt-lg-4">
              <h2 className="h1"><span className='bg-faded-primary rounded text-primary px-3 py-2'>First Derivative</span> FAQ</h2>
            </div>
            <div className="row pt-3 pt-lg-4">
              <div className="col-md-6">
                <div className="d-flex mb-4 pb-2"><i className="ai-help-circle h4 text-success"></i>
                  <div className="ps-3">
                    <h3 className="h5">What is an NFT derivative?</h3>
                    <p className="fs-md mb-0">First Derivative is a distributed ethereum smart contract that allows anyone to mint their own NFT which "tracks" an existing underlying NFT, similar to a financial derivative that tracks an underlying commodity or security.</p>
                  </div>
                </div>
                <div className="d-flex mb-4 pb-2"><i className="ai-help-circle h4 text-success"></i>
                  <div className="ps-3">
                    <h3 className="h5">How many First Derivatives can there be?</h3>
                    <p className="fs-md mb-0">There can only ever be a single First Derivative NFT for each underlying NFT. Once a derivative for an individual NFT has been claimed, a second can not be minted for the same underlying NFT.</p>
                  </div>
                </div>
                <div className="d-flex mb-4 pb-2"><i className="ai-help-circle h4 text-success"></i>
                  <div className="ps-3">
                    <h3 className="h5">What NFT formats does First Derivative support?</h3>
                    <p className="fs-md mb-0">All minted First Derivative tokens are ERC-721, but can track underlying ERC-721, ERC-1155, or CryptoPunks.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="d-flex mb-4 pb-2"><i className="ai-help-circle h4 text-success"></i>
                    <div className="ps-3">
                      <h3 className="h5">How do my derivatives display in my wallet?</h3>
                      <p className="fs-md mb-0">First Derivatives are ERC-721 NFTs that track an underlying NFT, so they will display identically to the underlying NFT in your wallet (or on an NFT marketplace like OpenSea). For example, a First Derivative of CryptoPunk #42 will display identically to CryptoPunk #42.</p>
                    </div>
                  </div>
                <div className="d-flex mb-4 pb-2"><i className="ai-check-circle h4 text-success"></i>
                  <div className="ps-3">
                    <h3 className="h5">Can I mint a derivative of a derivative?</h3>
                    <p className="fs-md mb-0">That would be a "second derivative." The depth of allowable derivatives is controlled by the First Derivative smart contract, and is currently set to 1. In the future, deeper derivatives may be possible.</p>
                  </div>
                </div>
                <div className="d-flex mb-4 pb-2"><i className="ai-check-circle h4 text-success"></i>
                  <div className="ps-3">
                    <h3 className="h5">How is an ERC-721 tokenURI generated for underlying NFTs like CryptoPunks with no tokenURI equivalent?</h3>
                    <p className="fs-md mb-0">The First Derivative contract synthesizes a data URI on-chain which resolves to a JSON blob that describes the underlying and includes an encoded image data URI in SVG format from the CryptoPunksData smart contract.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>


  );
}