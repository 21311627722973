import React, { useEffect, useState } from 'react';
import { FirstDerivative__factory } from '../typechain';
import { FIRST_DERIVATIVE_ADDRESS } from '../util/Constants';
import { BigNumber } from '@ethersproject/bignumber';
import { NFTMetadata } from '../util/NFTMetadata';
import NFTCard from './NFTCard';
import { fetchDerivativeMetadata, useEthNetwork } from '../util/NetworkConnector';

export default function RecentDerivativesSection() {

  const [ active, library ]                           = useEthNetwork();
  const [recentDerivatives, setRecentDerivatives]     = useState<RecentDerivative[]>([]);
  const [isLoading, setIsLoading]                     = useState(true);

  interface RecentDerivative {
    tokenId : BigNumber;
    underlyingCollectionId: string;
    underlyingTokenId: BigNumber;
    underlyingMetadata: NFTMetadata;
  }

  useEffect(() => {
    async function updateRecentDerivatives() {
      try {
        const firstDerivativeContract   = FirstDerivative__factory.connect(FIRST_DERIVATIVE_ADDRESS, library);
        const totalSupply               = await firstDerivativeContract.totalSupply();
        let result : RecentDerivative[] = [];
  
        for (let i=0;i<9;i++) {
          let tokenId = totalSupply.sub(i);
  
          if (!tokenId.isNegative() && !tokenId.isZero()) {
            const underlyingPromise : Promise<[string, BigNumber]> = firstDerivativeContract.getUnderlying(tokenId);
            const metadataPromise   : Promise<NFTMetadata>         = fetchDerivativeMetadata(tokenId.toString());

            try {
              const [[underlyingCollectionId, underlyingTokenId], metadata] = await Promise.all([underlyingPromise, metadataPromise]);  
              result.push({tokenId: tokenId, underlyingCollectionId: underlyingCollectionId, underlyingTokenId: underlyingTokenId, underlyingMetadata: metadata});
            } catch (error) {
              console.error(error);
            }
          }
        }
  
        console.log("Setting recent derivatives: " + JSON.stringify(result));
        setRecentDerivatives(result);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }  
    }
    console.log("Conditionally updating recent derivatives: " + active);
    if (active) updateRecentDerivatives();
  }, [active, library]);

  function renderRecentDerivatives() {
    if (!isLoading) {
      return recentDerivatives.reduce((resultArray : RecentDerivative[][], item, index) => { 
        const chunkIndex = Math.floor(index/4);
      
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
      
        resultArray[chunkIndex].push(item);
      
        return resultArray;
      }, []).map((derivativesChunk : RecentDerivative[]) => {
        return (<div className="row">
          {derivativesChunk.map((derivative : RecentDerivative) => {
            return (<div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key={derivative.tokenId.toString()} metadata={derivative.underlyingMetadata} contractId={derivative.underlyingCollectionId} tokenId={derivative.underlyingTokenId.toString()} derivativeId={derivative.tokenId} lastSalePrice={undefined} displayButton={true}/>}</div>);
          })}
        </div>)
      });  
    } else {
      return (<div className="row">
                <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard metadata={undefined} contractId={"placeholderOne"} tokenId={"placeholderOne"} derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
                <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard metadata={undefined} contractId={"placeholderOne"} tokenId={"placeholderOne"} derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
                <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard metadata={undefined} contractId={"placeholderOne"} tokenId={"placeholderOne"} derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
              </div>);
    }
  }

  return (
    <section className="bg-secondary" id="demos">
      <div className="container pt-5 pb-4 py-md-6 py-lg-7">
        <div className="text-center mb-5 pt-3 pt-lg-4">
          <h2 className="h1 mb-4">Recently minted <span className='bg-faded-primary rounded text-primary px-3 py-2'>First Derivatives</span></h2>
          <p className="text-muted">The latest from the first derivative mint</p>
        </div>
        {renderRecentDerivatives()}
      </div>
    </section>
  );

}