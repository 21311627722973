import { BigNumber } from '@ethersproject/bignumber';
import React, { useEffect, useState } from 'react';
import { FirstDerivative, FirstDerivative__factory } from '../typechain';
import { FIRST_DERIVATIVE_ADDRESS, POPULAR_COLLECTIONS } from '../util/Constants';
import { useEthNetwork } from '../util/NetworkConnector';
import { NFTMetadata } from '../util/NFTMetadata';
import NFTCard from './NFTCard';
import { Network, OpenSeaAPI } from 'opensea-js';
import { assetFromJSON } from 'opensea-js/lib/utils';
import { OpenSeaAsset } from 'opensea-js/lib/types';

interface OpenseaItem {
  collectionId: string,
  tokenId: string,
  metadata: NFTMetadata,
  derivativeId: BigNumber,
  lastSalePrice: string | undefined
}

export default function PopularNFTSection() {
  const [openSeaItems, setOpenSeaItems] = useState<OpenseaItem[]>([]);
  const [isLoaded, setLoaded]           = useState(false);
  const [active, library]               =  useEthNetwork();

  async function getRawAssets() : Promise<OpenSeaAsset[]> {
    const api  = new OpenSeaAPI({networkName: Network.Main});
    const json = await api.get('/api/v1/assets/', {limit: 50, offset: 0, order_by:"sale_price", asset_contract_addresses: POPULAR_COLLECTIONS});
    const results = json.assets.map((j: any) => assetFromJSON(j));

    return results.sort(() => .5 - Math.random()).slice(0,20);
  }

  useEffect(() => {
    async function loadOpenSeaItems() {
      const assets = await getRawAssets();
      const items  = await Promise.all(assets.map(async (asset) =>  {
        const firstDerivativeContract : FirstDerivative = FirstDerivative__factory.connect(FIRST_DERIVATIVE_ADDRESS, library);

        let derivativeId : BigNumber;

        try {
          derivativeId = await firstDerivativeContract.getDerivative(asset.assetContract.address, BigNumber.from(asset.tokenId));
        } catch(e) {
          console.error(e);
          derivativeId = BigNumber.from(0);
        }

        return {
          collectionId: asset.assetContract.address,
          tokenId: asset.tokenId ?? "",
          metadata: {
            name: asset.name,
            description: asset.description,
            image: asset.imageUrl
          },
          derivativeId: derivativeId,
          lastSalePrice: asset.lastSale?.totalPrice
        }
      }));

      setOpenSeaItems(items);
      setLoaded(true);
    }

    if (active) loadOpenSeaItems();
  }, [active, library]);

  function renderItems() {
    if (isLoaded) {
      return openSeaItems.reduce((resultArray : OpenseaItem[][], item, index) => { 
        const chunkIndex = Math.floor(index/4)
      
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
      
        resultArray[chunkIndex].push(item)
      
        return resultArray
      }, []).map((openseaItemsChunk : OpenseaItem[], index: number) => {
        return (<div className="row">
        {openseaItemsChunk.map((item : OpenseaItem) => {
          return (<div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key={item.collectionId + item.tokenId} metadata={item.metadata} contractId={item.collectionId} tokenId={item.tokenId} derivativeId={item.derivativeId} lastSalePrice={item.lastSalePrice} displayButton={true}/>}</div>);
        })}
      </div>)
      })
    } else {
      return (
        <>
          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key="placeholder1" metadata={undefined} contractId="placeholder1" tokenId="placeholder" derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
            <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key="placeholder2" metadata={undefined} contractId="placeholder2" tokenId="placeholder" derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
            <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key="placeholder3" metadata={undefined} contractId="placeholder3" tokenId="placeholder" derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
            <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key="placeholder4" metadata={undefined} contractId="placeholder3" tokenId="placeholder" derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key="placeholder5" metadata={undefined} contractId="placeholder1" tokenId="placeholder" derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
            <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key="placeholder6" metadata={undefined} contractId="placeholder2" tokenId="placeholder" derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
            <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key="placeholder7" metadata={undefined} contractId="placeholder3" tokenId="placeholder" derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
            <div className="col-lg-3 col-sm-6 mb-grid-gutter pb-3">{<NFTCard key="placeholder8" metadata={undefined} contractId="placeholder3" tokenId="placeholder" derivativeId={BigNumber.from(0)} lastSalePrice={undefined} displayButton={false}/>}</div>
          </div>
        </>
      );
    }
  }

  return (
    
    <section className="bg-gradient py-5 py-md-6 py-lg-7">
      <div className="container pt-5 pb-4 py-md-6 py-lg-7">
        <div className="text-center mb-5 pt-3 pt-lg-4">
          <h2 className="h1 mb-4 text-light">Popular NFTs</h2>
          <p className="text-light opacity-70">Browse for inspiration</p>
        </div>
        {renderItems()}
      </div>
    </section>

  );
}