export const FIRST_DERIVATIVE_ADDRESS : string = "0x8B333EE34B692450D85580c8B33B48949e09a916";

export const CRYPTOPUNKS_ADDRESS : string = '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB';

export const CRYPTOPUNKS_DATA_ADDRESS : string = '0x16F5A35647D6F03D5D3da7b35409D65ba03aF3B2';

export const METADATA_RETRIEVER_HOST : string = 'q1eqjq6hld.execute-api.us-east-1.amazonaws.com';

export const POPULAR_COLLECTIONS : string[] = 
[CRYPTOPUNKS_ADDRESS, // cryptopunks
'0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270', // artblocks curated
'0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d', // boredape yacht club
'0x1a92f7381b9f03921564a437210bb9396471050c', // CoolCats
'0x4b3406a41399c7fd2ba65cbc93697ad9e7ea61e5', // Lostpoets
'0x12d2d1bed91c24f878f37e66bd829ce7197e4d14', // Galactic Apes
'0xe785e82358879f061bc3dcac6f0444462d4b5330', // world of women
'0x60e4d786628fea6478f785a6d7e704777c86a7c6', // mutant ape yacht club
];

