import React, {useEffect, useState} from 'react';
import { useWeb3React } from "@web3-react/core"
import { BigNumber, ContractTransaction } from 'ethers';
import { NFTMetadata } from '../util/NFTMetadata';
import NFTCard from './NFTCard';
import { FIRST_DERIVATIVE_ADDRESS } from '../util/Constants';
import useBus from 'use-bus';

import './MintForm.css'
import MintCollectionsRail from './MintCollectionsRail';
import MintTokensRail from './MintTokensRail';
import { ContractFascade } from '../util/ContractFascade';
import { FirstDerivative, FirstDerivative__factory } from '../typechain';

enum ContractIdValidity {
  EMPTY, VALID, INVALID
}

enum TokenIdValidity {
  EMPTY, VALID, INVALID, CLAIMED, PROGRESS, MINTED
}

interface MintFormProps {
  contractId: string | undefined;
  tokenId: string | undefined;
}

export default function MintForm(props: MintFormProps) {

  const [contractId, setContractId]                               = useState(props.contractId ?? '');
  const [contractInterface, setContractInterface]                 = useState<ContractFascade | undefined>(undefined);
  const [contractName, setContractName]                           = useState('');
  const [isValidContractId, setIsValidContractId]                 = useState(ContractIdValidity.EMPTY);

  const [tokenId, setTokenId]                                     = useState(props.tokenId ?? '');
  const [tokenMetaData, setTokenMetaData]                         = useState<NFTMetadata | undefined>(undefined);
  const [isValidTokenId, setIsValidTokenId]                       = useState(TokenIdValidity.EMPTY);
  const [derivativeId, setDerivativeId]                           = useState(BigNumber.from(0));
  const [isMinting, setIsMinting]                                 = useState(false);
  const [mintingStatus, setMintingStatus]                         = useState('');

  const { library } = useWeb3React()

  async function handleContractIdChange(event: React.FormEvent<HTMLInputElement>) {
    setContractId(event.currentTarget.value);
    setIsValidContractId(ContractIdValidity.EMPTY);
  }

  const handleTokenIdChange = (event : React.FormEvent<HTMLInputElement>) => {
    setTokenId(event.currentTarget.value);
    setIsValidTokenId(TokenIdValidity.EMPTY);
  };

  useBus('@mintModal/display', (event) => {
    console.log("Got @mintModal/display");
    setContractId(event.payload.contractId);
    setTokenId(event.payload.tokenId);
  }, [contractId, tokenId]);

  useEffect(() => {
    async function updateContractInterface() {
      try {
        const contract : ContractFascade = await ContractFascade.Create(contractId, library);
        setContractInterface(contract);
      } catch (error) {
        console.error(error);
        setContractInterface(undefined);
      }
    }

    updateContractInterface();
  }, [contractId, library]);


  useEffect(() => {
    async function updateContractValidity() {
      if (contractId === '') {
        setIsValidContractId(ContractIdValidity.EMPTY);
        return;
      }

      if (contractInterface !== undefined) {
        setIsValidContractId(ContractIdValidity.VALID);
      } else {
        setIsValidContractId(ContractIdValidity.INVALID);
      }
    }

    updateContractValidity();
  }, [contractId, contractInterface]);

  useEffect(() => {
    async function updateContractName() {
      if (contractInterface === undefined) {
        setContractName('');
        return;
      }

      try {
        setContractName(await contractInterface.name());
      } catch (e) {
        console.error(e);
        setContractName('');
      }
    }

    updateContractName();
  }, [contractInterface]);

  useEffect(() => {
    async function updateTokenIdValidity() {
      if (tokenId === '' || contractInterface === undefined) {
        setIsValidTokenId(TokenIdValidity.EMPTY);
        setTokenMetaData(undefined);
        setDerivativeId(BigNumber.from(0));
        return;
      }

      setIsValidTokenId(TokenIdValidity.PROGRESS);

      try {
        console.log("Querying for tokenUri");
        const metadata = await contractInterface.tokenMetadata(tokenId);

        setTokenMetaData(metadata);

        const firstDerivativeContract = FirstDerivative__factory.connect(FIRST_DERIVATIVE_ADDRESS, library);
        const derivativeId            = await firstDerivativeContract.getDerivative(contractId, tokenId);

        setDerivativeId(derivativeId);

        if (derivativeId.isZero()) {
          setIsValidTokenId(TokenIdValidity.VALID);          
        } else {
          setIsValidTokenId(TokenIdValidity.CLAIMED);
        }
      } catch (e) {
        console.error(e);
        setIsValidTokenId(TokenIdValidity.INVALID);
        setTokenMetaData(undefined);
      }
    }

    updateTokenIdValidity();
  }, [contractInterface, contractId, tokenId, library]);

  /*
  useEffect(() => {
    async function updateTokenMetaData() {
      if (tokenUri === '') {
        setTokenMetaData(undefined);
        return;
      }

      setIsLoadingTokenMetadata(true);

      try {
        const metadata : NFTMetadata = await fetchJson(tokenUri);

        setTokenMetaData(metadata);
      } catch (e) {
        console.error(e);
        setTokenMetaData(undefined);
      }

      setIsLoadingTokenMetadata(false);
    }

    updateTokenMetaData();
  }, [tokenUri]);
*/
  function getContractIdValidationStateClass() {
    switch (isValidContractId) {
      case ContractIdValidity.EMPTY  : return '';
      case ContractIdValidity.VALID  : return 'is-valid';
      case ContractIdValidity.INVALID: return 'is-invalid';
    }
  }

  function getTokenIdValidationStateClass() {
    switch (isValidTokenId) {
      case TokenIdValidity.EMPTY: return '';
      case TokenIdValidity.VALID: return 'is-valid';
      case TokenIdValidity.INVALID: return 'is-invalid';
      case TokenIdValidity.CLAIMED: return 'is-invalid';
      case TokenIdValidity.MINTED: return 'is-valid';
    }
  }

  function getTokenCard() {
    if (isValidTokenId === TokenIdValidity.PROGRESS) {
      return (<NFTCard metadata={undefined} contractId={contractId} tokenId={tokenId} derivativeId={derivativeId} displayButton={false} lastSalePrice={undefined}/>);
    } else if (tokenMetaData !== undefined) {
      return (<NFTCard metadata={tokenMetaData} contractId={contractId} tokenId={tokenId} derivativeId={derivativeId} displayButton={false} lastSalePrice={undefined}/>);
    }

    return (<></>)
  }

  function getTokenIdInvalidDescription() {
    if (isValidTokenId === TokenIdValidity.INVALID) {
      return (<div className="invalid-feedback">No such token</div>);
    } else if (isValidTokenId === TokenIdValidity.CLAIMED) {
      return (<div className="invalid-feedback">Derivative already claimed</div>);
    } else if (isValidTokenId === TokenIdValidity.MINTED) {
      return (<div className="valid-feedback">Minted!</div>);
    } else {
      return (<></>);
    }
  }

  function getMintbutton() {
    switch (isValidTokenId) {
      case TokenIdValidity.MINTED:
      case TokenIdValidity.CLAIMED:
        return (<a href={"https://etherscan.io/token/" + FIRST_DERIVATIVE_ADDRESS + "?a=" + derivativeId} className="btn btn-info" role="button" target="_blank" rel="noreferrer">View derivative</a>);
      case TokenIdValidity.EMPTY:
      case TokenIdValidity.INVALID:
        return (<button type="button" className="btn btn-secondary" disabled>Mint derivative</button>);
      case TokenIdValidity.VALID:
        if (isMinting) {
          return (<button type="button" onClick={handleMint} className="btn btn-translucent-success">
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Minting derivative...</button>);
        } else {
          return (<button type="button" onClick={handleMint} className="btn btn-success">Mint derivative</button>);
        }
    }
  }

  async function handleMint() {
    if (isMinting) return;
    setIsMinting(true);
    setMintingStatus('Creating transaction...');

    const firstDerivativeContract : FirstDerivative = FirstDerivative__factory.connect(FIRST_DERIVATIVE_ADDRESS, library.getSigner());
    const price                                     = await firstDerivativeContract.derivativePrice();

    try {
      const mintedTokenId : ContractTransaction = await firstDerivativeContract.mintDerivative(contractId, tokenId, {value: price});

      setMintingStatus("Waiting for 1 confirmation...");

      const receipt         = await mintedTokenId.wait(1);
      const transferEvents  = receipt.events?.filter((x) => {return x.event === "Transfer"});
      const transferArgs    = transferEvents?.pop()?.args;

      if (transferArgs && transferArgs.length === 3) {
        const tokenId : BigNumber = transferArgs[2];
        setDerivativeId(tokenId);
        setIsValidTokenId(TokenIdValidity.MINTED);
      }

      setMintingStatus("");
    } catch (error) {
      console.error(error);
      setMintingStatus("Minting failed!");
    }

    setIsMinting(false);
  }

  function renderTokensRail() {
    if (isValidContractId === ContractIdValidity.VALID) {
      return (<div className="row">
                <div className="col-lg-12">
                  <MintTokensRail collectionId={contractId} onTokenId={(tokenId : string) => {setTokenId(tokenId)}} />
                </div>
              </div>);
    } else {
      return (<></>);
    }

  }

  return (
    <div className="container-fluid">
      <div className="row">
        <p className="fs-5">Enter an NFT contract and token ID:</p>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="form-floating mb-2">
            <input className={"form-control " + getContractIdValidationStateClass()} value={contractId} onChange={handleContractIdChange} type="text" id="contract-address" placeholder="Collection address"/>
            <label htmlFor="contract-address">Collection address</label>
            <div className="valid-feedback">{contractName}</div>
          </div>

          <div className="form-floating mb-2">
            <input className={"form-control " + getTokenIdValidationStateClass()} value={tokenId} onChange={handleTokenIdChange} type="text" id="token-id" placeholder="Token ID"/>
            <label htmlFor="token-id">Token ID</label>
            {getTokenIdInvalidDescription()}
          </div>

          <div className="form-floating mt-3">
            {getMintbutton()}
          </div>

          <div className="form-floating mt-1">
            <p className="fs-sm">{mintingStatus}</p>
          </div>
        </div>

        <div className="col-lg-7">
          {getTokenCard()}
        </div>
      </div>

      <div className="row mt-5">
        <p className="fs-5">Or browse popular collections</p>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <MintCollectionsRail onCollection={(collectionId: string) => {setContractId(collectionId); setTokenId("");}}/>
        </div>
      </div>

      {renderTokensRail()}
    </div>
  );


}