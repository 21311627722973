import { BigNumber } from '@ethersproject/bignumber';
import React, { useEffect, useState } from 'react';
import { FirstDerivative__factory } from '../typechain';
import { FIRST_DERIVATIVE_ADDRESS } from '../util/Constants';
import { useEthNetwork } from '../util/NetworkConnector';

export default function StatsComponent() {

  const [mintedCount, setMintedCount] = useState(0);
  const [ownerCount, setOwnerCount]   = useState(0);
  const [active, library]             = useEthNetwork();

  useEffect(() => {
    async function calculateStatistics() {
      const firstDerivativeContract = FirstDerivative__factory.connect(FIRST_DERIVATIVE_ADDRESS, library);
      const totalSupply : BigNumber = await firstDerivativeContract.totalSupply();
      const allOwners: string[]     = await Promise.all(Array.from(Array(totalSupply.toNumber()).keys()).map((index) => {
        return firstDerivativeContract.ownerOf(index + 1);
      }));

      setMintedCount(totalSupply.toNumber());
      setOwnerCount(new Set(allOwners).size);
    }

    if (active) calculateStatistics();
  }, [active, library]);

  return (<div className="row">
            <div className="col-sm-4 mb-4 mb-sm0">
              <div className="h1 text-light mb-1">{mintedCount}</div>
              <div className="h5 text-light fw-normal opacity-70 mb-2">Minted Derivatives</div>
            </div>
            <div className="col-sm-4 mb-4 mb-sm-0">
              <div className="h1 text-light mb-1">{ownerCount}</div>
              <div className="h5 text-light fw-normal opacity-70 mb-1">Owners</div>
            </div>
          </div>
);
}